import React, { useState } from "react";
import { Facebook, Mail, LogIn } from "react-feather";
import { CiLogin, CiShop } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "reactstrap";
import { login } from "../redux/action/auth";
import { useNavigate } from "react-router";
import "./index.css";
import { Link } from "react-router-dom";
import { _get, _post } from "../utils/Helper";

export default function Login({ setType, toggle, onLoginSuccess }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = { email: form.email, password: form.password };

    _post(
      "api/users/login",
      obj,
      (res) => {
        // setLoading(false);
        // alert(JSON.stringify(res.userDetails))
        let data = res?.userDetails.id;
        localStorage.setItem("@@toke_$$_45598", JSON.stringify(data));
        if (res.role === "user") {
          alert("Logged Successful");
          onLoginSuccess();
          // navigate("/");
        } else {
          alert("User not found");
        }
      },
      (err) => {
        // setLoading(false);
        alert("An error occurred!");
        console.log(err);
      }
    );
    // dispatch(login(form, () => toggle()));
  };

  return (
    <Form style={{ padding: "none" }} onSubmit={handleSubmit}>
      <div className="singIn">
        <h1 className="SingUp-first-header">Login</h1>
        <div className="input-div">
          <Input
            onChange={handleChange}
            name="email"
            className="SingUp-first-input"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="input-div">
          <Input
            onChange={handleChange}
            name="password"
            className="SingUp-first-input"
            type="password"
            placeholder="Password"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label className="labe-for-sign">
            <Input type="checkbox" className="labe-for-sign2" />
            <span>Remember me</span>
          </label>
          <a href="#" className="href">
            Forget password?
          </a>
        </div>
        <Button type="submit" className="btn btn-for-signUp2">
          <CiLogin style={{ marginRight: 5 }} size={24} className="fw-bold" />{" "}
          Login
        </Button>
        <p className="text-center">- OR -</p>
        {/* <Button className="btn-for-signUp3">
					<Mail style={{ marginRight: 10 }} /> Login with Google
				</Button>
				<Button className="btn-for-signUp2">
					<Facebook style={{ marginRight: 10 }} /> Login With Facebook
				</Button> */}

        <Link
          to={"https://app.kasuwamall.com/"}
          className="btn btn-for-signUp2"
        >
          <CiShop style={{ marginRight: 5 }} size={24} className="fw-bold" />{" "}
          Login as Seller
        </Link>
        <div
          className="d-flex justify-content-center"
          style={{ textAlign: "center", marginTop: 15 }}
        >
          <p className="me-2">New user?</p>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setType("Signup");
            }}
            className="link-for-login-signup"
          >
            Sign Up Here
          </a>
        </div>
      </div>
    </Form>
  );
}
