import React, { useState } from "react";
import { Facebook, Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { Button, Form, Input, FormGroup, Row, Spinner } from "reactstrap";
import { signup } from "../redux/action/auth";
import { _post } from "../utils/Helper";

export default function SignUp({ setType, toggle }) {
  // const { auth:{authenticated} } = useSelector((s)=>s)
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    username: "",
    address: "",
    status: "approved", // Default status for customers
    role: "user", // Default role for customers
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value, type, checked } }) => {
    setForm((s) => ({ ...s, [name]: type === "checked" ? checked : value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Add some basic client-side validation if needed
    if (!form.firstname || !form.lastname || !form.email || !form.password) {
      alert("All fields are required!");
      return;
    }

    console.log("Form Data: ", form); // Debug form data
    setLoading(true);

    _post(
      "api/users/create",
      form, // Pass form data to the API
      (res) => {
        alert("Created Successfully");
        setLoading(false);
      },
      (err) => {
        alert("An error occurred!");
        console.log(err);
        setLoading(false);
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <div className="singIn2">
          <div>
            <h1 className="SingUp-first-header">Create New Account</h1>
            {/* {JSON.stringify(form)} */}
            <div className="input-div">
              {/* <Col md={6}> */}
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={form.firstname}
                  onChange={handleChange}
                />
              </FormGroup>
              {/* </Col>
              <Col md={6}> */}
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                />
              </FormGroup>
              {/* </Col> */}
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="Username"
                  name="username"
                  value={form.username}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Phone Number (+234)"
                  className="SingUp-first-input"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="Email"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="text"
                  placeholder="Password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="SingUp-first-input"
                  type="textarea"
                  name="address"
                  value={form.address}
                  placeholder="Address"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <label
                style={{
                  marginLeft: 15,
                  marginBottom: 10,
                }}
              >
                <Input
                  style={{
                    cursor: "pointer",
                    marginLeft: 4,
                  }}
                  type="checkbox"
                  name="accepted_terms"
                  checked={form.accepted_terms}
                  value={form.accepted_terms}
                  onChange={handleChange}
                />
                <span
                  style={{
                    marginLeft: 10,
                  }}
                  className="cursor"
                >
                  I accecpt the
                </span>
                <a
                  href=""
                  className="href"
                  style={{
                    marginLeft: 4,
                  }}
                >
                  Term & Condition
                </a>
                and Privacy and Cookie Notice.
              </label>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button onClick={handleSubmit} className="btn-for-signUp2" disabled={loading}>
                {loading ? <Spinner /> : <>Sign Up</>}
              </button>
              {/* <br />
              <p className="text-center">- OR -</p>
              <br />
              <Button className="btn-for-signUp2">
                <Mail
                  style={{
                    color: "#fff",
                    width: 18,
                    height: 17,
                    borderRadius: 4,
                    float: "left",
                    margin: 3,
                  }}
                />
                Login with Google
              </Button>
              <Button className="btn-for-signUp2">
                <Facebook
                  style={{
                    color: "#FFFFFF",
                    width: 18,
                    height: 17,
                    borderRadius: 4,
                    float: "left",
                    margin: 3,
                  }}
                />
                Login With Facebook
              </Button> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
                justifyContent: "center",
              }}
            >
              <p>Already have an Account ?</p>
              {
                // eslint-disable-next-line
              }
              <a
                href="/#"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setType("Login");
                }}
                style={{ marginLeft: 15 }}
                className="link-for-login-signup"
              >
                LogIn
              </a>
            </div>
          </div>
        </div>
      </Row>
    </Form>
  );
}
