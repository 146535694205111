import React from "react";
import { Trash, CheckCircle } from "react-feather";
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  Row,
  Table,
  CardHeader,
  CardFooter,
} from "reactstrap";

import visa from "../Images/visa.png";
import master from "../Images/master.png";
import verve from "../Images/verve.png";
import CartCard from "./CartCard";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "../Auths/AuthModal";
import { useState } from "react";
import { _postApi } from "../redux/action/api";
import { deleteCarts } from "../redux/action/shop";
import { FaArrowLeft } from "react-icons/fa";
import CartTable from "./CartTable";
import "./shopcart.css";
import { useNavigate } from "react-router";
import { _post, separator } from "../utils/Helper";

export default function ShopCart() {
  const {
    shop: { carts },
  } = useSelector((s) => s);
  const dispatch = useDispatch();
  const [auth_type, setAuthType] = useState("Login");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleclick = () => {
    if (!carts.length) {
      alert("Your cart is empty! Please add items before submitting.");
      return;
    }

    setIsSubmitting(true);
    const data = carts.map((dt) => ({
      customer_id: 2,
      product: dt.product_name,
      quantity: dt.qty,
      product_id: dt.id,
      status: "Pending",
      shop_id: dt.shop_id,
      order_image: dt.selectedImage,
    }));

    _post(
      "api/createorders",
      { products: data },
      (res) => {
        console.log("Order created successfully:", res);
        setIsSubmitting(false);
        alert("Order successfully placed!");
        dispatch(deleteCarts());
      },
      (err) => {
        console.error("Failed to place order:", err);
        alert("Failed to place order. Please try again.");
        setIsSubmitting(false);
      }
    );
  };

  const handlenavigate = () => {
    navigate("/");
  };

  const handleButtonClick = () => {
    if (isLoggedIn) {
      handleclick(); // If logged in, submit the order
    } else {
      toggleModal(); // If not logged in, open the login modal
    }
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true); // Set login state to true
    toggleModal(); // Close the modal after successful login
  };

  return (
    <>
      <div
        className="container extra-margin-top"
        div
        style={{ paddingBottom: 20 }}
      >
        <header>
          <h1>Shoppping Cart</h1>
        </header>

        <div className="mt-3 mb-4">
          <Button onClick={handlenavigate} className="cont-bnt">
            <FaArrowLeft /> Contimue Shoppping
          </Button>
        </div>
        {/* {JSON.stringify(carts)} */}
        {/* <CartTable /> */}
        <Row className="mt-3">
          <Col md={9}>
            <CartTable />
          </Col>
          <Col md={3}>
            <Card className="shadow p-3 cart-right-card">
              <CardHeader className="d-flex justify-content-betwee  n">
                <h3 style={{ fontSize: "15px" }}>Order Summary</h3>
                <h3 style={{ fontSize: "15px" }}>
                  {carts.length && carts.reduce((p, c) => p + c.qty, 0)} Items
                </h3>
              </CardHeader>
              {/* <h4>Summary</h4> */}
              <Row className="mt-4">
                <Col md={7}>
                  <h6 style={{ fontSize: "12px", padding: "0", margin: "0" }}>
                    Delivery charges
                  </h6>
                </Col>
                <Col md={5}>
                  <p
                    style={{
                      fontSize: "10px",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Add your Delivery address at checkout to see delivery
                    charges
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt-1">
                <Col md={7}>
                  <h6 style={{ fontSize: "12px", padding: "0", margin: "0" }}>
                    Subtotal
                  </h6>
                </Col>
                <Col md={5}>
                  <h4
                    style={{
                      fontSize: "10px",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    &#8358;{" "}
                    {separator(
                      carts.length &&
                        carts.reduce((p, c) => p + c.qty * c.product_price, 0)
                    )}
                  </h4>
                </Col>
              </Row>
              <hr />
              <Row className="mt-1">
                <Col md={7}>
                  <h6
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Total
                  </h6>
                </Col>
                <Col md={5}>
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    &#8358;{" "}
                    {separator(
                      carts.length &&
                        carts.reduce((p, c) => p + c.qty * c.product_price, 0)
                    )}
                  </h4>
                </Col>
              </Row>
              <hr />
              <div>
                <p
                  className="text-danger"
                  style={{ fontSize: "10px", textAlign: "right" }}
                >
                  Excluding delivery charges
                </p>
              </div>
              <Button
                onClick={handleButtonClick}
                className="check mb-0"
                disabled={isSubmitting}
              >
                {isLoggedIn ? "Submit Order" : "Continue"}
              </Button>
              {/* <Button
                onClick={() => {
                  handleclick();
                }}
                className="check mb-0"
              >
                submit
              </Button> */}
              <CardFooter>
                <p className="" style={{ fontSize: "13px" }}>
                  We accept :{" "}
                  <img style={{ width: "40px" }} alt="Master" src={master} />{" "}
                  <img style={{ width: "40px" }} alt="Visa" src={visa} mar />{" "}
                  <img style={{ width: "40px" }} alt="Visa" src={verve} mar />{" "}
                </p>
              </CardFooter>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                  <AuthModal
                    type={auth_type}
                    toggle={toggle}
                    setType={setAuthType}
                    onLoginSuccess={handleLoginSuccess}
                  />
                </ModalBody>
              </Modal>
            </Card>
            {/* <Card className="shadow p-3 mt-3 cart-right-card">
              <h5>Payments Methods</h5>
              <Row className="">
                <Col md={2}>
                  <img alt="Master" src={master} />
                </Col>
                <Col md={2}>
                  <img alt="Visa" src={visa} mar />
                </Col>
              </Row>
              <hr></hr>
              <h5>Buyer Protection</h5>
              <p style={{ fontSize: 14 }}>
                <CheckCircle /> Get full refund if the item is not as described
                or if is not delivered
              </p>
            </Card> */}
          </Col>
        </Row>
      </div>
    </>
  );
}
