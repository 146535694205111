import React from 'react';
import { Container, Row, Col, Input, Button,NavLink } from 'reactstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaTwitter, FaFacebookF, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import './footer.css';
import { globalColor } from '../../utils/Helper';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="container-fluid text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style={{ marginTop: '3rem', visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn', backgroundColor: '#542b2b' }}>
            <Container className="">
                <Row className="g-5">
                    <Col lg={3} md={6}>
                        <h4 className="text-light mb-4">Contact Us</h4>
                        <p className="mb-2"><FaMapMarkerAlt className="me-3" />No 33. H.M House behind Rahama, Unity road, Kantin Kwari. Kano State.</p>
                        <p className="mb-2"><FaPhoneAlt className="me-3" />+234 701 722 2999</p>
                        <p className="mb-2"><FaEnvelope className="me-3" />help@kasuwamall.com</p>
                        <div className="d-flex pt-2">
                            <NavLink className="btn-outline-light btn-social"><FaTwitter /></NavLink>
                            <Link to={"https://www.facebook.com/kasuwamall/"} className="btn-outline-light btn-social"><FaFacebookF /></Link>
                            <NavLink className="btn-outline-light btn-social"><FaYoutube /></NavLink>
                            <NavLink className="btn-outline-light btn-social"><FaLinkedinIn /></NavLink>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <h4 className="text-light mb-4">Customer Service</h4>
                        <li className="btn-link"><NavLink href="">Help Center</NavLink> </li>
                        <li className="btn-link"><NavLink href="">Returns</NavLink> </li>
                        <li className="btn-link"><NavLink href="">Shipping Info</NavLink> </li>
                        <li className="btn-link"><NavLink href="">Track Order</NavLink> </li>
                        <li className="btn-link"><NavLink href="">FAQs</NavLink> </li>
                    </Col>
                    <Col lg={3} md={6}>
                    <ul>
                        <h4 className="text-light mb-4">Company</h4>
                        <li className="btn-link"><NavLink>About Us</NavLink></li>
                        <li className="btn-link"><NavLink>Careers</NavLink></li>
                        <li className="btn-link"><NavLink>Privacy Policy</NavLink></li>
                        <li className="btn-link"><NavLink>Terms & Conditions</NavLink></li>
                        <li className="btn-link"><NavLink>Contact Us</NavLink></li>
                        </ul>
                    </Col>
                    <Col lg={3} md={6}>
                        <h4 className="text-light mb-4">Newsletter</h4>
                        <p>Sign up for exclusive offers and updates.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
                            <Input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <Button className="btn btns py-2 position-absolute top-0 end-0 mt-2 me-2">Sign Up</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="copyright">
                    <Row>
                        <Col md={6} className="text-center text-md-start mb-3 mb-md-0">
                            © <a className="border-bottom" href="#">KASUWAMALL Inc</a>, All Rights Reserved.
                        </Col>
                        <Col md={6} className="text-center text-md-end">
                            {/* Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
                            <br />Distributed By <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a> */}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Footer;
